import React from 'react';
import Head from 'next/head';
import clsx from 'clsx';
import dynamic from 'next/dynamic';

import { useLoyaltyModal, useSeoWebpageMarkup, useLocale } from '@hooks';
import { mapLocaleToTwoLetterTrackingCountryCode } from '@utils/locale/mappers';
import { Footer } from '@organisms';

const LoyaltyModal = dynamic(
  () => import('@components/organisms/LoyaltyModal'),
  {
    ssr: false
  }
);

const Layout = ({
  children,
  title,
  appendToTitle = true,
  metaDescription,
  isBackground = false,
  backgroundColor,
  dataTestId,
  hideFooterNewsletterPromotion = false,
  ignoreMinHeight = false
}: {
  children: React.ReactNode;
  title?: string;
  appendToTitle?: boolean;
  metaDescription?: string;
  isBackground?: boolean;
  backgroundColor?: 'gray' | 'black';
  dataTestId?: string;
  hideFooterNewsletterPromotion?: boolean;
  ignoreMinHeight?: boolean;
}) => {
  const backgroundClasses = isBackground ? 'bg-warm-gray-1' : '';
  const showLoyaltyModal = useLoyaltyModal();
  const seoWebpageMarkup = useSeoWebpageMarkup(
    title ?? '',
    metaDescription ?? ''
  );
  const locale = useLocale();
  const countryCode =
    locale !== 'en'
      ? mapLocaleToTwoLetterTrackingCountryCode(locale).toLocaleUpperCase()
      : '';

  return (
    <main className="flex flex-col">
      <Head>
        <title>{`${title ?? ''}${
          appendToTitle ? ` | HX Hurtigruten Expeditions ${countryCode}` : ''
        }`}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription}></meta>
        )}
        {title && metaDescription && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(seoWebpageMarkup)
            }}
          />
        )}
      </Head>
      {!!backgroundColor && (
        <div
          className={clsx('absolute inset-0 w-screen h-screen', {
            'bg-black': backgroundColor === 'black',
            'bg-warm-gray-2': backgroundColor === 'gray'
          })}
        />
      )}
      <div
        className={clsx('relative', backgroundClasses, {
          'min-h-screen-minus-header': !ignoreMinHeight
        })}
      >
        <div>
          <div data-testid={dataTestId}>{children}</div>
          {showLoyaltyModal ? <LoyaltyModal /> : null}
        </div>
      </div>
      <Footer hideNewsletterPromotion={hideFooterNewsletterPromotion} />
    </main>
  );
};

export default Layout;
